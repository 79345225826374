<template>
  <div v-if="topic" class="pt-3">
    <page-breadcrumb :title="`Breaking Topic Detail`" :items="breadcrumbItems" />

    <section class="my-3">
      <h2 class="h2">Infomation</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="topic.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <strong class="h6">Title</strong>
            <!-- <b-form-input type="text" v-model="topic.title" placeholder="Enter your title here" /> -->
            <ui-component :component-data="topic.title" class="border" />
            <strong class="h6 d-block mt-1">Type</strong>
            <div class="d-flex mt-1">
              <b-select
                v-model="topic.type"
                name="topic-type"
                :options="typeOptions"
                disabled
              />
            </div>
            <div class="pt-1">
              <strong class="h6">Is published?</strong>
              <div class="d-flex">
                <b-form-checkbox
                  class="custom-control-primary"
                  name="is_publish_checkbox"
                  switch
                  v-model="topic.is_published"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckCircleIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XCircleIcon" />
                  </span>
                </b-form-checkbox>
                <strong>{{ topic.is_published ? "Yes" : "No" }}</strong>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <section class="my-3">     
      <h2 class="h2">Overview</h2>
      <ui-component :component-data="topic.overview_content" class="border" />
      <!-- <ui-component-list :component-list="topic.content" /> -->
    </section>
    <section class="my-3">
      <div class="d-flex justify-content-between">
        <h2 class="h2">Content</h2>
        <div class="mb-1">
          <b-button class="mr-1" variant="outline-primary" @click="copyContentToClipboard()">Export content</b-button>
          <b-button variant="outline-secondary" @click="importContentFromClipboard()">Import content</b-button>
        </div>
      </div>
      <breaking-component :component-data="topic" />
    </section>
    <action-buttons update-button back-button delete-button
      @update="update" :is-updating="isUpdating" @delete="deleteTopic" :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from '../service'
import SentenceCreateForm from './_components/SentenceCreateForm.vue'
import SentenceCard from './_components/SentenceCard.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    SentenceCreateForm,
    SentenceCard,
    draggable,
  },
  data() {
    return {
      topic: null,
      isUpdating: false,
      isDeleting: false,
      typeOptions:[
        {text: "Từ vựng chủ đạo", value: 1},
        {text: "Luyện tập nghe ngấm", value: 2},
        {text: "Luyện tập", value: 3},
        {text: "Luyện tập phát âm cùng AI", value: 4},
        {text: "Luyện kỹ năng nói đuổi chuyên sâu", value: 5},
        {text: "Nói đuổi nâng cao cùng AI", value: 6},
        {text: "Phản xạ kỹ năng chuyên sâu", value: 7},
      ]
    }
  },
  computed: {
    topicId() {
      return this.$route.params.topic_id
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Breaking Lessons',
          to: { name: 'breaking-lesson-list' },
        },
        {
          text: `Lesson: ${this.lesson.title || this.lesson._id}`,
          to: { name: 'breaking-lesson-detail', params: { lesson_id: this.lesson._id } },
        },
        {
          text: `Step: ${this.topic.step_key || 'NaN'}`,
          to: { name: 'breaking-lesson-detail', params: { lesson_id: this.lesson._id } },
        },
      ]
      items.push({ text: `Breaking Topic:  ${this.topic.title || this.topic._id}`, active: true })
      return items
    },
  },
  created() {
    this.getTopic()
  },
  methods: {
    async getTopic() {
      let { document, lesson_document } = await service.get({ id: this.topicId })
      this.topic = document
      this.lesson = lesson_document
    },
    async update() {
      this.isUpdating = true
      const response = await service.update({ data: JSON.stringify(this.topic) })
      if (response.data.statusCode === 200) {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated topic!' })
      }
      this.getTopic();
      this.isUpdating = false;
    },
    async deleteTopic() {
      this.isDeleting = true
      const response = await service.delete({ id: this.topicId })
      if (response.data.statusCode === 200) {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully deleted topic!' })
      }
      this.isDeleting = false
      this.$router.push({ name: 'breaking-lesson-detail', params: { lesson_id: this.lesson._id } })
    },
    async addSentence(sentence) {
      this.topic.sentences.push(sentence)
    },
    async removeSentence(sentenceIndex) {
      this.topic.sentences.splice(sentenceIndex, 1)
    },

    copyContentToClipboard() {
      if (this.topic.content) {
        navigator.clipboard.writeText(JSON.stringify({
          content: this.topic.content,
          type: this.topic.type
        }))
        this.$store.dispatch('pushSuccessNotify', { text: 'Exported content successfully!' })
      }
    },

    async importContentFromClipboard() {
      const dataTopic = JSON.parse(await navigator.clipboard.readText())
      if (dataTopic && dataTopic.content) {
        if (this.compareObjectKeys(dataTopic.content, this.topic.content)){
          this.topic.content = dataTopic.content
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported content successfully!' })
        }
        else{
          this.$store.dispatch('pushErrorNotify', { text: 'Topic content is not the same!' })
          return 
        }
      }
    },
    compareObjectKeys(a,b){
      let aKeys = Object.keys(a).sort();
      let bKeys = Object.keys(b).sort();
      return JSON.stringify(aKeys) === JSON.stringify(bKeys);
    }
  },
}
</script>

<style scoped>
.page-item {
  transition: ease 0.6s
}
</style>
